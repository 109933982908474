import _ from "../shared/utils/lodash"
import { useRef, useState } from "react"

const DraggableCircle = (props: {
	minPrice: number
	maxPrice: number
	startingPrice: number
	parentElem: HTMLDivElement
	type: "LeftSide" | "RightSide"
	onChange: (value: number) => void
}) => {
	const { parentElem, startingPrice, minPrice, maxPrice } = props
	const parentRect = parentElem.getBoundingClientRect()
	let startingPointX =
		((startingPrice - minPrice) / (maxPrice - minPrice)) * parentRect.width
	// if maxPrice and minPrice are the same, startingPointX will end up being NaN
	if (maxPrice === minPrice) {
		// shift it all the way to the right side
		startingPointX = props.type === "RightSide" ? parentRect.width : 0
	}

	const elem = useRef<HTMLDivElement>(null)

	const onDrag = (
		e: React.DragEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
	) => {
		let xPosition = 0
		// TODO : FIX MEEEEEEEE
		// @ts-expect-error:TODO FIX
		xPosition = e.clientX

		if (e instanceof DragEvent) {
			xPosition = e.x
		} else {
			// const targetTouch = e.targetTouches[0];
			// // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			// if (targetTouch == null) {
			//   console.error(
			//     'FD: PriceRange.onTouchMove: target touch was not defined',
			//   );
			//   return;
			// }
			// xPosition = targetTouch.pageX;
		}

		if (xPosition === 0) {
			return
		}

		let difference = xPosition - parentRect.x
		difference = Math.min(difference, parentRect.width)
		difference = Math.max(difference, 0)
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		elem.current!.style.transform = `translate3d(${difference}px, -50%, 0)`

		const diffInPercentage = difference / parentRect.width
		const selectedPrice = (maxPrice - minPrice) * diffInPercentage + minPrice
		props.onChange(parseInt(selectedPrice.toFixed(0)))
	}

	return (
		<div
			className="flex items-center justify-center"
			ref={elem}
			onDragStart={(_e) => {
				const e = _e
				// hide the drag image
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				const crt = elem.current!.cloneNode(true) as HTMLDivElement
				crt.style.opacity = "0"
				document.body.appendChild(crt)
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				e.dataTransfer!.setDragImage(crt, 0, 0)
			}}
			style={{
				left: 0,
				zIndex: 5,
				width: 40,
				// margin left should always be - half of the width to get it centering in the middle
				marginLeft: -20,
				opacity: 1,
				height: 40,
				top: "50%",
				fontSize: 16,
				color: "white",
				borderRadius: 5,
				cursor: "pointer",
				position: "absolute",
				willChange: "transform",
				backgroundColor: "#000",
				transformOrigin: "50% 50%",
				border: "0.1rem solid white",
				boxShadow: "rgb(0 0 0 / 31%) 0px 0px 0.4",
				// translate#d(0px) is not valid but we need to translateY by -50% so we have to do this check
				// startingPointX can be 0 if the min and max price are the same
				transform:
					startingPointX === 0
						? "translateY(-50%)"
						: `translate3d(${startingPointX}px, -50%, 0)`
			}}
			draggable={true}
			onTouchMove={(e) => {
				onDrag(e)
			}}
			onDrag={(_e) => {
				const e = _e
				onDrag(e)
			}}
			onDragEnd={(_e) => {
				const e = _e
				onDrag(e)
			}}>
			||
		</div>
	)
}

type Props = {
	minPrice: number
	maxPrice: number
	currencyCode: string
	onChange: (data: { minPrice: number; maxPrice: number }) => void
}

type State = {
	_resetKey: number
	leftValue: number
	rightValue: number
	parentElem: null | HTMLDivElement
}

const PriceRange = (props: Props) => {
	const [state, setState] = useState<State>({
		_resetKey: 0,
		leftValue: _.cloneDeep(props.minPrice),
		rightValue: _.cloneDeep(props.maxPrice),
		parentElem: null
	})

	function resetPriceRange() {
		setState({
			_resetKey: state._resetKey + 1,
			leftValue: _.cloneDeep(props.minPrice),
			rightValue: _.cloneDeep(props.maxPrice),
			parentElem: null
		})
	}

	function getPriceRange(): { minPrice: number; maxPrice: number } {
		const prices = _.sortBy([state.leftValue, state.rightValue], (value) => value)
		const minPrice = prices[0]
		const maxPrice = prices[1]
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (minPrice == null || maxPrice == null) {
			throw new Error("PriceRnage.getPriceRnage is missing min or max price")
		}

		return {
			minPrice,
			maxPrice
		}
	}

	const debouncedOnChange = _.debounce(
		() => {
			const priceRange = getPriceRange()
			props.onChange(priceRange)
		},
		250,
		{ leading: false }
	)

	const { parentElem } = state
	const { maxPrice, minPrice } = getPriceRange()
	let relativeDistance = (maxPrice - minPrice) / (props.maxPrice - props.minPrice)
	let shiftFromLeft = (minPrice - props.minPrice) / (props.maxPrice - props.minPrice)

	if (props.maxPrice === props.minPrice) {
		relativeDistance = 1
		shiftFromLeft = 0
	}

	return (
		<div
			id="PriceRange"
			className=""
			style={{
				margin: "0 auto",
				maxWidth: 400,
				width: "100%",
				padding: "10px 0px",
				position: "relative",
				textAlign: "center"
			}}>
			<div
				className="py-2 sm:mt-2"
				style={{
					fontSize: 15
				}}>
				{minPrice} {props.currencyCode} - {maxPrice} {props.currencyCode}
			</div>
			<div
				style={{
					minHeight: 40,
					// don't let it reach the ends if the parent width is flush with the screen sides
					width: "80%",
					margin: "0 auto",
					position: "relative"
				}}
				className="range-bar">
				<div
					className="bar"
					ref={(ref) => {
						if (state.parentElem != null) {
							return
						}
						setState((prevState) => {
							prevState.parentElem = ref
							return {
								...prevState
							}
						})
					}}
					style={{
						zIndex: 1,
						height: 5,
						width: "100%",
						borderRadius: 5,
						position: "absolute",
						backgroundColor: "white",
						border: "1px solid #000",
						top: "50%",
						transform: "translate(0, -50%)"
					}}>
					<div
						className="difference"
						style={{
							zIndex: 1,
							height: "100%",
							borderRadius: 5,
							position: "absolute",
							backgroundColor: "#000",
							width: `${relativeDistance * 100}%`,
							left: `${shiftFromLeft * 100}%`
						}}></div>

					{parentElem == null ? null : (
						<DraggableCircle
							type="LeftSide"
							key={state._resetKey + "1"}
							parentElem={parentElem}
							minPrice={props.minPrice}
							maxPrice={props.maxPrice}
							startingPrice={props.minPrice}
							onChange={(value) => {
								if (value === state.leftValue) {
									return
								}
								setState((prevState) => {
									prevState.leftValue = value
									return {
										...prevState
									}
								})

								debouncedOnChange()
							}}
						/>
					)}

					{parentElem == null ? null : (
						<DraggableCircle
							type="RightSide"
							key={state._resetKey + "2"}
							parentElem={parentElem}
							minPrice={props.minPrice}
							maxPrice={props.maxPrice}
							startingPrice={props.maxPrice}
							onChange={(value) => {
								if (value === state.rightValue) {
									return
								}
								setState((prevState) => {
									prevState.rightValue = value
									return {
										...prevState
									}
								})

								debouncedOnChange()
							}}
						/>
					)}
				</div>
			</div>
		</div>
	)
}
export default PriceRange
