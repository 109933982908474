// export * from "../../../../brando-no.myshopify.com/src/modules/search/BoostAPI"

/* eslint-disable @typescript-eslint/no-namespace */

type MoneyV2 = {
	currencyCode: "NOK"
	amount: string
}

export type BoostAPIProduct = {
	title: string
	handle: string
	price_max: number
	price_min: number
	id: number
	images: {
		1?: string
		2?: string
		3?: string
		4?: string
	}

	available: boolean
	available_locations: Array<number>
	body_html: string
	collections: Array<{
		handle: string
		id: number
	}>
	compare_at_price_max: null | number
	compare_at_price_min: null | number
	images_info: Array<{
		src: string
		height: number
		width: number
	}>
	locations: Array<string>
	options_with_values: Array<{
		label: string
		name: string
		values: Array<{
			title: string
		}>
	}>
	original_tags: Array<string>
	product_category: null | string
	product_type: string
	tags: Array<string>
	variants: Array<{
		available: boolean
		available_locations: Array<number>
		compare_at_price: string
		id: number
		image: string
		price: string
		title: string
		merged_options: Array<`${string}:${string}`>
	}>
	vendor: null | string
	metafields: null | Array<
		| {
				namespace: "custom"
				type: "list.product_reference" | "single_line_text_field" | "string"
				key: "string"
				value: string
		  }
		| {
				namespace: "custom"
				type: "money"
				key: "medlemspris"
				value: `{"amount":"1801.0","currency_code":"NOK"}`
		  }
	>
}

export type ParsedBoostProduct = {
	type: "ParsedBoostProduct"
	id: number
	title: string
	handle: string
	price: number
	vendor?: null | string
	available: boolean
	tags: Array<string>
	priceRange: {
		minVariantPrice: MoneyV2
		maxVariantPrice: MoneyV2
	}
	options: Array<{
		name: string
		position: 1 | 2 | 3
		values: Array<string>
	}>
	compareAtPriceRange: {
		minVariantPrice: MoneyV2
		maxVariantPrice: MoneyV2
	} | null
	compare_at_price: null | number
	variants: Array<{
		id: number
		title: string
		option1: null | string
		option2: null | string
		option3: null | string
		available: boolean
		price: number
		compare_at_price: null | number
		options: Array<string>
		selectedOptions: Array<{
			name: string
			value: string
		}>
	}>
	description: string
	media: Array<{
		media_type: "image"
		aspect_ratio: number
		src: string
	}>
	featured_image?: null | string
	metafields: BoostAPIProduct["metafields"]
}

export type BoostAPISortBy =
	| "manual"
	| "title-ascending"
	| "title-descending"
	| "title-s-ascending"
	| "title-s-descending"
	| "price-ascending"
	| "price-descending"
	| "variant-price-ascending"
	| "variant-price-descending"
	| "sale-ascending"
	| "sale-descending"
	| "published-ascending"
	| "published-descending"
	| "created-ascending"
	| "created-descending"
	| "best-selling"
	| "relevance"
	| "best-selling-ascending"
	| "best-selling-descending"
	| "relevance-ascending"
	| "relevance-descending"
	| "review-ratings-ascending"
	| "review-ratings-descending"
	| "extra-sort1-ascending"
	| "extra-sort1-descending"
	| "extra-sort2-ascending"
	| "extra-sort2-descending"
	| "extra-sort3-ascending"
	| "extra-sort3-descending"

export namespace BoostAPI {
	export type SearchOpts = {
		q: null | string
		collectionId?: null | string
		filterBy: BoostAPIFilterBy
		sortBy: null | BoostAPISortBy
		page?: number
		tag: null | string
		limit: number
	}

	export type Filter = {
		options: Array<BoostAPIFilterOption>
	}
}

// prettier-ignore
export type BoostAPIFilterOption = {
	displayType: string
	filterOptionId: string
	filterType: "vendor" | "product_type" | "tag"
	label: string
	// not clear if this is defined here or not
	manualValues?: Array<string>
	values: [
		{
			key: string
			doc_count: number
		}
	]
} | {
	displayType: string
	filterOptionId: string
	filterType: "price"
	label: string
	values: {
		max: null | number
		min: null | number
	}
} | {
	displayType: string
	filterOptionId: string
	filterType: "percent_sale"
	label: string,
	manualValues: Array<string>
	values: [
		{
			key: string
			doc_count: number
		}
	]
}

export type activeSegment = null | "Barn" | "Dame" | "Herre"

export type BoostAPISearchResults = {
	affected_by_merchandising: false
	from_cache: boolean
	filter: BoostAPI.Filter
	products: Array<BoostAPIProduct>
	total_collection: number
	total_page: number
	total_product: number
}

export type BoostAPITargetFilter = {
	filterID: string
	value: string
	manualValue: null | string
}
export type BoostAPIFilterBy = Array<BoostAPITargetFilter>

export const boostAPIBaseUrl = "https://services.mybcapps.com/bc-sf-filter/search"
export const currencyCode = "NOK"

// https://help.boostcommerce.net/article/458-filter-search-api
export const boostAPI = {
	getSortByOptions(args: {
		locales: {
			search: {
				sort_by_relevency: string
				sort_by_z_to_a: string
				sort_by_a_to_z: string
				sort_by_reviews: string
				sort_by_oldest: string
				sort_by_newest: string
				sort_by_price_low_to_high: string
				sort_by_price_high_to_low: string
			}
		}
	}) {
		const { locales } = args
		const searchSortByOptions: Array<{
			label: string
			type: BoostAPISortBy
		}> = [
			{
				label: locales.search.sort_by_price_low_to_high,
				type: "price-ascending"
			},
			{
				label: locales.search.sort_by_price_high_to_low,
				type: "price-descending"
			},
			{
				label: locales.search.sort_by_newest,
				type: "published-descending"
			},
			{
				label: locales.search.sort_by_oldest,
				type: "published-ascending"
			},
			{
				label: locales.search.sort_by_reviews,
				type: "review-ratings-ascending"
			},
			{
				label: locales.search.sort_by_a_to_z,
				type: "title-ascending"
			},
			{
				label: locales.search.sort_by_z_to_a,
				type: "title-descending"
			},
			{
				label: locales.search.sort_by_relevency,
				type: "relevance"
			}
		]

		return searchSortByOptions
	},

	parseUrl: (args: { href: string }) => {
		const url = new URL(args.href)
		let page = 1
		const filterBy: BoostAPIFilterBy = []
		let sortBy: BoostAPI.SearchOpts["sortBy"] = null
		let q: null | string = null
		let collectionId: null | string = null
		let tag: null | string = null

		url.searchParams.forEach((value, key) => {
			if (key === "sort") {
				sortBy = value as BoostAPISortBy
			} else if (key === "page") {
				page = parseInt(value)
			} else if (key === "q") {
				q = value
			} else if (key === "pf_c_collection") {
				collectionId = value
			} else if (key === "tag") {
				tag = value
			} else if (typeof value === "string" && key.includes("pf")) {
				value.split("&").map((value) => {
					filterBy.push({
						filterID: key.replace("[]", ""),
						value,
						manualValue: null
					})
				})
			}
		})

		const searchOpts: BoostAPI.SearchOpts = {
			sortBy,
			page,
			q,
			filterBy,
			collectionId,
			tag,
			limit: 68
		}

		return searchOpts
	},

	convertToProductObject: (boostProduct: BoostAPIProduct): ParsedBoostProduct => {
		const featured_image: undefined | string =
			boostProduct.images[1] ||
			boostProduct.images[2] ||
			boostProduct.images[3] ||
			boostProduct.images[4]

		const { compare_at_price_max, compare_at_price_min, price_max } = boostProduct

		const available = boostProduct.available === true
		// || boostProduct.variants.find((variant) => variant.available === true) != null

		const productOptions = boostProduct.options_with_values.map((option, i) => {
			return {
				position: (i + 1) as 1 | 2 | 3,
				name: option.name,
				values: option.values.map((value) => value.title)
			}
		})

		// need to multiple by hundred to convert to cents to normalize as it would be had it come from Shopify
		const price = price_max * 100
		const compare_at_price =
			compare_at_price_max != null ? compare_at_price_max * 100 : null
		const compareAtPriceMax =
			compare_at_price_max != null ? compare_at_price_max * 100 : null
		const compareAtPriceMin =
			compare_at_price_min != null ? compare_at_price_min * 100 : null

		let compareAtPriceRange: ParsedBoostProduct["compareAtPriceRange"] | null = null
		if (compareAtPriceMax != null && compareAtPriceMin != null) {
			compareAtPriceRange = {
				minVariantPrice: {
					currencyCode: "NOK",
					amount: (compareAtPriceMax / 100).toFixed(2)
				},
				maxVariantPrice: {
					currencyCode: "NOK",
					amount: (compareAtPriceMin / 100).toFixed(2)
				}
			}
		}

		return {
			type: "ParsedBoostProduct",
			metafields: boostProduct.metafields,
			id: boostProduct.id,
			handle: boostProduct.handle,
			title: boostProduct.title,
			tags: boostProduct.tags,
			vendor: boostProduct.vendor,
			description: boostProduct.body_html,
			featured_image: featured_image || null,
			priceRange: {
				minVariantPrice: {
					currencyCode: "NOK",
					amount: (price / 100).toFixed(2)
				},
				maxVariantPrice: {
					currencyCode: "NOK",
					amount: (price / 100).toFixed(2)
				}
			},
			compare_at_price,
			compareAtPriceRange,
			price,
			// need to multiple by hundred to convert to cents to normalize as it would be had it come from Shopify
			available,
			options: productOptions,
			variants: boostProduct.variants.map((variant) => {
				const selectedOptions: ParsedBoostProduct["variants"][0]["selectedOptions"] =
					[]

				variant.merged_options.forEach((mergedOption) => {
					const name = mergedOption.split(":")[0]!
					const value = mergedOption.split(":")[1]!

					selectedOptions.push({ name, value })
				})

				const option1 = selectedOptions.find((option, i) => i === 0)
				const option2 = selectedOptions.find((option, i) => i === 1)
				const option3 = selectedOptions.find((option, i) => i === 2)
				const options = [option1, option2, option3].filter((option) => option != null)

				if (options.length !== boostProduct.options_with_values.length) {
					console.error("FD: invalid # of options parsed from boostProduct")
				}

				let compare_at_price: null | number = null
				if (variant.compare_at_price != null) {
					compare_at_price = parseFloat(variant.compare_at_price) * 100
				}

				return {
					option1: option1 ? option1.value : null,
					option2: option2 ? option2.value : null,
					option3: option3 ? option3.value : null,
					options: selectedOptions.map((option) => option.value),
					...variant,
					compare_at_price,
					selectedOptions,
					price: parseFloat(variant.price) * 100
				}
			}),
			media: boostProduct.images_info.map((image) => {
				return {
					src: image.src,
					aspect_ratio: image.width / image.height,
					media_type: "image"
				}
			})
		}
	},

	search(
		opts: BoostAPI.SearchOpts & {
			myshopifyDomain: string
		}
	): Promise<BoostAPISearchResults> {
		const url = new URL("https://services.mybcapps.com/bc-sf-filter/search")
		url.searchParams.set("shop", opts.myshopifyDomain)
		if (opts.collectionId != null) {
			url.searchParams.set("collection_scope", opts.collectionId)
			url.searchParams.set("pf_c_collection", opts.collectionId)
		}

		url.searchParams.set("fuzzy", "2")
		url.searchParams.set("limit", `${opts.limit}`)
		if (opts.q != null) {
			url.searchParams.set("q", opts.q)
		}

		// only return available products
		url.searchParams.set("product_available", "true")
		url.searchParams.set("variant_available", "true")

		url.searchParams.set("build_filter_tree", "true")

		if (opts.page != null) {
			url.searchParams.set("page", `${opts.page}`)
		}

		if (opts.sortBy != null) {
			url.searchParams.set("sort", opts.sortBy)
		}

		if (opts.tag != null && opts.tag !== "undefined" && opts.tag !== "null") {
			url.searchParams.set("tag", opts.tag)
		}

		// the boost app itself sends these when a location is selected but it doesnt seem to do much
		// url.searchParams.set("pf_vli_butikklager_ibsl", "true")
		// url.searchParams.set("inventory_base_selected_locations", "true")

		let fetchUrl = url.toString()
		opts.filterBy.forEach((filter) => {
			fetchUrl = `${fetchUrl}&${filter.filterID}[]=${
				filter.manualValue || filter.value
			}`
		})
		return fetch(fetchUrl)
			.then((res) => {
				return res
			})
			.then((res) => res.json())
			.then((data: any) => {
				return data
			})
	},

	instantSearch(args: {
		q: string
		segmentFilterTag: null | string
		myshopifyDomain: string
	}): Promise<{
		affected_by_merchandising: boolean
		all_empty: boolean
		collections: Array<{
			handle: string
			id: number
			title: string
		}>
		did_you_mean: Array<string>
		products: Array<BoostAPIProduct>
		query: string
		suggestions: string[]
		total_product: number
	}> {
		const url = new URL("https://services.mybcapps.com/bc-sf-filter/search/suggest")
		url.searchParams.set("shop", args.myshopifyDomain)
		url.searchParams.set("q", args.q)

		// client wants it by default like this
		url.searchParams.set("sort", "published-descending")

		if (
			args.segmentFilterTag != null &&
			args.segmentFilterTag !== "undefined" &&
			args.segmentFilterTag !== "null"
		) {
			url.searchParams.set("tag", args.segmentFilterTag)
		}

		return fetch(`${url.toString()}`).then((res) => res.json())
	},

	updateUrlAndHistoryState: (opts: BoostAPI.SearchOpts) => {
		const { q, page, sortBy, filterBy: filterby, collectionId, tag } = opts
		const url = new URL(window.location.href)

		// const existingParams = url.searchParams.forEach

		// delete all search params everything (use this approach instead of deleting each key)
		url.search = ""

		// setting page in URL
		if (page) {
			url.searchParams.set("page", `${page}`)
		}

		if (sortBy != null) {
			url.searchParams.set("sort", sortBy)
		}

		if (q) {
			url.searchParams.set("q", q)
		}

		if (collectionId) {
			url.searchParams.set("pf_c_collection", collectionId)
		}

		if (tag != null && tag !== "undefined" && tag !== "null") {
			url.searchParams.set("tag", tag)
		}

		let fetchUrl = url.toString()
		filterby.filter((filter) => {
			fetchUrl = `${fetchUrl}&${filter.filterID}[]=${
				filter.manualValue || filter.value
			}`
		})

		window.history.pushState({ reload: true }, "", fetchUrl)
	},

	buildRequestURL(opts: BoostAPI.SearchOpts) {
		const url = new URL(boostAPIBaseUrl)
		// TODO : CHANGE window.shop to hardcoded one
		url.searchParams.set("shop", "brando-no.myshopify.com")
		if (opts.collectionId != null) {
			url.searchParams.set("collection_scope", opts.collectionId)
		}
		url.searchParams.set("fuzzy", "2")
		url.searchParams.set("limit", `${opts.limit}`)
		if (opts.q != null) {
			url.searchParams.set("q", opts.q)
		}

		url.searchParams.set("build_filter_tree", "true")

		url.searchParams.set("product_available", "true")
		url.searchParams.set("variant_available", "true")

		if (opts.page != null) {
			url.searchParams.set("page", `${opts.page}`)
		}

		if (opts.sortBy != null) {
			url.searchParams.set("sort", opts.sortBy)
		}

		if (opts.collectionId != null) {
			url.searchParams.set("pf_c_collection", opts.collectionId)
		}

		if (opts.tag != null) {
			url.searchParams.set("tag", opts.tag)
		}

		let fetchUrl = url.toString()
		opts.filterBy.forEach((filter) => {
			fetchUrl = `${fetchUrl}&${filter.filterID}[]=${
				filter.manualValue || filter.value
			}`
		})

		return fetchUrl
	}
}
