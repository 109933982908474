import _ from "../../../shared/utils/lodash"
import React, { CSSProperties } from "react"
import { App } from "~/types"
import { useGlobalStore } from "../../../shared/useGlobalStore"

type Props = {
	segmentedMenus: App.NavMenu
	className?: string
	segmentClassName?: string
	style?: CSSProperties
	onClickSegmentedMenu: (menu: App.NavMenu) => void
}

const SegmentedMenu = (props: Props) => {
	const activeSearchSegmentFilterTag = useGlobalStore(
		(state) => state.search.activeSearchSegmentFilterTag
	)
	return (
		<div
			style={{
				...props.style
			}}
			className={`md:container flex items-center justify-center md:space-x-4 uppercase ${
				props.className || ""
			}`}>
			{_.map(props.segmentedMenus.children, (menu) => {
				const isActive =
					menu.title.toLowerCase() === activeSearchSegmentFilterTag?.toLowerCase()

				return (
					<div
						key={menu.url}
						data-test="search-segmented-menu"
						className={`p-1 max-md:w-1/3 text-lg ${
							props.segmentClassName != null ? props.className : ""
						}`}
						onClick={() => {
							props.onClickSegmentedMenu(menu)
						}}
						style={{
							cursor: "pointer",
							textAlign: "center",
							borderBottom: "2px solid transparent",
							fontWeight: isActive === true ? "bold" : "normal",
							borderBottomColor: isActive === true ? "#000" : "transparent"
						}}>
						{menu.title}
					</div>
				)
			})}
		</div>
	)
}
export default React.memo(SegmentedMenu, (prevProps, nextProps) => {
	return prevProps.segmentedMenus === nextProps.segmentedMenus
})
