import CollapsibleItem from "./CollapsibleItem"
import { ApplyFilterFn } from "./SearchPage"

import {
	BoostAPIFilterBy,
	BoostAPIFilterOption,
	BoostAPISearchResults
} from "../SearchController"
import SVGIcon from "~/components/ui/SVGIcon"
import { useGlobalStore } from "~/shared/useGlobalStore"
import { CSSProperties, useRef, useState } from "react"
import PriceRange from "../../PriceRange"

const colorTags = [
	{
		tag: "Blå",
		hexCode: "#0087ff",
		productTitleColor: "Blå"
	},
	{
		tag: "Brun",
		hexCode: "#844e23",
		productTitleColor: "Brun"
	},
	{
		tag: "Grå",
		hexCode: "#a8a8a8",
		productTitleColor: "Grå"
	},
	{
		tag: "Grønn",
		hexCode: "#49e318",
		productTitleColor: "grønn"
	},
	{
		tag: "Gul",
		hexCode: "#fffa00",
		productTitleColor: "Gul"
	},
	{
		tag: "Gull",
		imgSrc:
			"https://cdn.shopify.com/s/files/1/0247/6261/3815/files/834CD95A-E03A-4EDD-A32D-08F55DC21729_4_5005_c.jpg?v=1653764566",
		hexCode: "",
		productTitleColor: "Gull"
	},
	{
		tag: "Lilla",
		hexCode: "#c200ff",
		productTitleColor: "Lilla"
	},
	{
		tag: "Oransje",
		hexCode: "#fc9c00",
		productTitleColor: "Oransje"
	},
	{
		tag: "Rosa",
		hexCode: "#ff00b2",
		productTitleColor: "Rosa"
	},
	{
		tag: "Rød",
		hexCode: "#ff0000",
		productTitleColor: "rød"
	},
	{
		tag: "Svart",
		hexCode: "#000000",
		productTitleColor: "Svart"
	},
	{
		tag: "Sølv",
		imgSrc:
			"https://cdn.shopify.com/s/files/1/0247/6261/3815/files/Silver_swatch1.jpg?v=1654382632",
		hexCode: "",
		productTitleColor: "Sølv"
	},
	{
		tag: "pink",
		hexCode: "#e83485",
		productTitleColor: "Cosmo pink"
	}
]
export type ProductColorTagData = {
	tag: string
	imgSrc?: null | string | undefined
	hexCode: string
}

function getColorTagData(option: { value: string; tagPrefix: string }): {
	hexCode: string
	imgSrc?: undefined | null | string
} {
	// ['Beige', 'Blå', 'Grønn', 'Hvit', 'Lilla'];
	const tag = option.value.replace(option.tagPrefix, "").toLowerCase()
	const data = colorTags.find((colorTag) => {
		return tag === colorTag.tag.toLowerCase()
	})

	if (data == null) {
		return {
			hexCode: tag
		}
	}

	return data
}

export const Checkbox = (props: {
	size?: number
	checked: boolean
	uncheckedColor?: string
	checkedColor?: string
}) => {
	const checkedColor = props.checkedColor || "#000"
	const uncheckedColor = props.uncheckedColor || "#000"
	const size = props.size || 22
	return (
		<div
			style={{
				position: "relative"
			}}>
			{props.checked === true ? (
				<div className="relative">
					<SVGIcon
						size={size}
						name="square"
						fill={checkedColor}
						color={checkedColor}
					/>
					<div className="transform-centered">
						<SVGIcon
							name="check"
							size={size - 10}
							color="white"
							strokeWidth={3}
						/>
					</div>
				</div>
			) : (
				<SVGIcon
					size={size}
					color={uncheckedColor}
					name="square"
				/>
			)}
		</div>
	)
}

export const CollapsibleFilterItem = (props: {
	filter: BoostAPIFilterOption
	applyFilter: ApplyFilterFn
	unapplyFilter: ApplyFilterFn
	results: BoostAPISearchResults
	selectedFilters: null | BoostAPIFilterBy
}) => {
	const { filter, results } = props

	const [showMoreFilters, setShowMoreFilters] = useState(false)

	const priceRangeRef = useRef<null>(null)

	const label = filter.label

	// Should be handled elsewhere but leaving it here for extra safety
	// if (filter[0] === 'Segment' || filter[2] === 'Segment') {
	//    return null
	// }

	// if (filter[0] === 'Price_max' || filter[0] === 'Price_min') {
	//    return null
	// }

	if (filter.filterType === "price") {
		const minPrice = filter.values.min
		const maxPrice = filter.values.max

		// const selectedNarrowPrice = _.find(results.narrow, (narrow) => {
		//    return narrow[0] === 'Price_from_to'
		// })

		if (minPrice == null || maxPrice == null) {
			return null
		}

		return (
			<CollapsibleItem
				id={`${filter.filterOptionId}`}
				defaultIsOpen={false}
				title={
					<div
						className="font-bold"
						style={{
							fontSize: 15
						}}>
						{label}
					</div>
				}
				renderBody={() => (
					<PriceRange
						key={`${minPrice}${maxPrice}`}
						minPrice={minPrice}
						maxPrice={maxPrice}
						currencyCode={"NOk"}
						onChange={(range) => {
							props.applyFilter({
								filterID: filter.filterOptionId,
								value: `${range.minPrice}:${range.maxPrice}`,
								manualValue: null
							})
						}}
					/>
				)}
			/>
		)
	}

	const options = filter.values
	const limit = showMoreFilters === true ? options.length : 5

	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	if (options.length <= 1) {
		return null
	} else if (filter.label.toLowerCase() === "farger") {
		return (
			<CollapsibleItem
				id={`filter-${filter.label}`}
				defaultIsOpen={false}
				title={
					<div
						className="font-bold"
						style={{
							fontSize: 15
						}}>
						{label}
					</div>
				}
				renderBody={() => (
					<div className="grid grid-cols-3 gap-4 pt-3">
						{options.map((option: (typeof options)[0], i: number) => {
							const isSelectedfilter: boolean =
								props.selectedFilters?.find((selectedFilter) => {
									return (
										selectedFilter.filterID === filter.filterOptionId &&
										selectedFilter.value === option.key
									)
								}) != null

							const colorTagData = getColorTagData({
								value: option.key,
								tagPrefix: ""
							})

							const size = 22
							const borderRadius = "100%"

							const filterID = filter.filterOptionId
							const value = option.key
							let manualValue: null | string = null
							if (Array.isArray(filter.manualValues)) {
								manualValue = filter.manualValues[i] || null
							}

							return (
								<div
									key={i}
									onClick={() => {
										if (isSelectedfilter === true) {
											props.unapplyFilter({ filterID, value, manualValue })
										} else {
											props.applyFilter({ filterID, value, manualValue })
										}
									}}
									className="text-center space-y-2 hover:opacity-50 cursor-pointer">
									<div
										style={{
											padding: 2,
											border: "1px solid #efefef",
											width: size,
											height: size,
											maxHeight: "100%",
											maxWidth: "100%",
											margin: "0 auto",
											borderRadius,
											flexShrink: 0,
											borderColor:
												isSelectedfilter === true ? "#000" : "#efefef"
										}}>
										<div
											style={{
												width: "100%",
												height: "100%",
												borderRadius,
												backgroundSize: "contain",
												backgroundColor:
													colorTagData.imgSrc == null
														? colorTagData.hexCode
														: undefined,
												backgroundImage:
													colorTagData.imgSrc != null
														? `url(${colorTagData.imgSrc})`
														: undefined
											}}
										/>
									</div>
									<div
										style={{
											fontSize: 12
										}}>
										{option.key}
									</div>
								</div>
							)
						})}
					</div>
				)}
			/>
		)
	} else {
		return (
			<CollapsibleItem
				id={`${filter.filterOptionId}`}
				defaultIsOpen={false}
				title={
					<div
						className="font-bold"
						style={{
							fontSize: 15
						}}>
						{label}
					</div>
				}
				renderBody={() => (
					<div className="space-y-2 py-2">
						<div className="flex lg:flex-col flex-wrap">
							{options.map((option, i: number) => {
								if (i > limit) {
									return
								}
								const isSelectedfilter: boolean =
									props.selectedFilters?.find((filter) => {
										return filter.value === option.key
									}) != null

								const filterID = filter.filterOptionId
								const value = option.key
								let manualValue: null | string = null
								if (Array.isArray(filter.manualValues)) {
									manualValue = filter.manualValues[i] || null
								}

								return (
									<div
										key={Math.random()}
										data-test="filter-option"
										className="cursor-pointer flex items-center"
										onClick={() => {
											if (isSelectedfilter === true) {
												props.unapplyFilter({ filterID, value, manualValue })
											} else {
												props.applyFilter({ filterID, value, manualValue })
											}
										}}>
										<div className="hidden lg:block">
											<Checkbox checked={isSelectedfilter === true} />
										</div>
										<div
											className="lg:!border-none max-lg:rounded-[4px] max-lg:my-1 max-lg:mr-2 border-solid max-lg:border-2"
											style={{
												fontSize: 12,
												flexShrink: 0,
												textTransform: "capitalize",
												padding: "8.5px",
												borderColor:
													isSelectedfilter === true ? "#000" : "#efefef"
											}}>
											{option.key} ({option.doc_count})
										</div>
									</div>
								)
							})}
						</div>

						{(options.length > limit || showMoreFilters === true) && (
							<div className="flex items-center space-x-1 cursor-pointer">
								{showMoreFilters === true ? (
									<SVGIcon
										name="minus"
										size={12}
										color="#000"
									/>
								) : (
									<SVGIcon
										name="plus"
										size={12}
										color="#000"
									/>
								)}
								<div
									className="text-[12px]"
									onClick={() => {
										// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
										setShowMoreFilters(!showMoreFilters)
									}}>
									{showMoreFilters === true ? "show less" : "show more"}
								</div>
							</div>
						)}
					</div>
				)}
			/>
		)
	}
}
